import React, { useContext } from 'react'
import classnames from 'classnames'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import styles from './Home.module.scss'
import { Button, Card, BackgroundWrapper, DotGrid, BlogCard, ScrollIndicator } from '../../components'
import { LanguageStrings } from '../../Context'
import { getImageObj } from '../../misc/utilities'

const Home = ({ data, projects }) => {
  const blogSection = (data.allWordpressPost.edges.map(edge => edge.node)).slice(0, 3)
  const texts = useContext(LanguageStrings)

  return (
    <div className={classnames(styles.container, 'pb-md')}>
      <Helmet>
        <meta property='og:type' content='Kosmos & Kaos' />
      </Helmet>
      <section className='py-l d-flex align-items-center'>
        <div className={classnames(styles.innerContainer, 'container')}>
          <BackgroundWrapper
            elements={[
              {
                top: '-7%',
                left: '-31px',
                offsetY: '10%',
                style: {
                  zIndex: -1
                },
                component: <DotGrid volumeX={20} volumeY={12} />
              }
            ]}
          >
            <div className='row'>
              <div className='col-md-10 offset-md-1'>
                <h1 className='headline mb-4' dangerouslySetInnerHTML={{ __html: texts.home.main_header }} />
                <p className='headline-text mb-4' >{ texts.home.under_header }</p>
                <p className='headline-text' ><strong>{ texts.home.welcome }</strong></p>
              </div>
            </div>
          </BackgroundWrapper>
        </div>
      </section>
      <ScrollIndicator />
      <section className='py-5'>
        <div className={classnames(styles.innerContainer, 'container')}>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='col-md-9 mb-2'>
              <h2>{texts.service.service_title}</h2>
            </div>
            <Button className='d-none d-lg-inline-block mb-4' to='/thjonusta'>{texts.home.to_service}</Button>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <Card
              theme='yellow'
              className='swiper-slide col-xl-3 col-sm-6 mb-3'
              subtitle={texts.service.consultancy_text}
            >
              <div className='headline-description p-3 pt-4'>
                <h2>{texts.service.consultancy_title}</h2>
              </div>
            </Card>
            <Card
              theme='yellow'
              className='swiper-slide col-xl-3 col-sm-6 mb-3'
              subtitle={texts.service.strategies_text}
            >
              <div className='headline-description p-3 pt-4'>
                <h2>{texts.service.strategies_title}</h2>
              </div>
            </Card>
            <Card
              theme='yellow'
              className='swiper-slide col-xl-3 col-sm-6 mb-3'
              subtitle={texts.service.design_text}
            >
              <div className='headline-description p-3 pt-4'>
                <h2>{texts.service.design_title}</h2>
              </div>
            </Card>
            <Card
              theme='yellow'
              className='swiper-slide col-xl-3 col-sm-6 mb-3'
              subtitle={texts.service.programming_text}
            >
              <div className='headline-description p-3 pt-4'>
                <h2>{texts.service.programming_title}</h2>
              </div>
            </Card>
          </div>
        </div>
      </section>
      <section className='py-5'>
        <div className={classnames(styles.innerContainer, 'container')}>
          <div className='row'>
            <div className='col-md-5'>
              <h2>
                {texts.service.UX_title}
              </h2>
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <div className='row'>
              <div className='col-md-9'>
                <p>
                  {texts.service.UX_text}
                </p>
              </div>
            </div>
            <Button className='d-none d-lg-inline-block' to='/verkefnin'>{texts.buttons.all_projects}</Button>
          </div>
          <div className='row'>
            {projects.map((project, index) => {
              return (
                <div
                  key={index}
                  className='col-6 col-md-4 mb-4'
                >
                  <Card
                    image={getImageObj(get(project, 'acf.image.sizes', {}), 'card')}
                    title={project.title.rendered}
                    bordered
                    linkTo={`/verkefnin/${project.slug}`}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
      {texts.lang !== 'en' &&
        <section className='py-5'>
          <div className={classnames(styles.innerContainer, 'container')}>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <h2>{texts.home.blog_container_title}</h2>
              <Button className='d-none d-lg-inline-block' to='/blog'>{texts.home.to_blogs}</Button>
            </div>
            <section>
              <BlogRow blogs={blogSection} />
            </section>
          </div>
        </section>
      }
    </div>
  )
}
const BlogRow = ({ blogs }) => (
  <div className='row'>
    {blogs.map((blog, index) => (
      <div key={index} className={classnames({ 'd-none d-md-flex': index === 2 }, 'col-6 col-md-4 mb-4')}>
        <BlogCard
          title={blog.title}
          author={blog.author}
          date={blog.date}
          href={'/blog/' + blog.slug}
          image={get(blog, 'acf.post_main_image.localFile.childImageSharp', {})}
        />
      </div>
    ))}
  </div>
)

export default Home

import React, { Component } from 'react'
import Cmp from './Home'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`{
          allWordpressPost(filter: {wordpress_id: {ne: 1900}}) {
            edges {
              node {
                slug
                title
                date
                author {
                  name
                  avatar_urls {
                    wordpress_96
                  }
                }
                acf {
                  post_main_image {
                    localFile {
                      childImageSharp {
                        large: fixed(height: 338, width: 545, quality: 92) {
                          tracedSVG
                          aspectRatio
                          width
                          height
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          originalName
                        }
                        small: fixed(height: 225, width: 333, quality: 92) {
                          tracedSVG
                          aspectRatio
                          width
                          height
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          originalName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`}

        render={data => (
          <Cmp
            data={data}
            projects={this.props.pageContext.projects}
            {...this.props}
          />
        )}
      />
    )
  }
}

export default Connected
